/* General Styles */
body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
  }
  
  /* Top Menu Bar Styles */
  .top-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #333;
    padding: 10px 20px;
  }
  
  .menu-items a {
    color: white;
    text-decoration: none;
    margin-right: 20px;
    padding: 5px 10px;
    transition: background-color 0.3s;
  }
  
  .menu-items a:hover {
    background-color: #555;
    border-radius: 5px;
  }
  
  .logout-button {
    background-color: #d9534f;
    color: white;
    border: none;
    padding: 10px 19px;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 5px;
  }
  
  .logout-button:hover {
    background-color: #c9302c;
  }
  
  /* PDF Holder Styles */
  .pdf-holder {
    background-color: white;
    margin: 20px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    text-align: center; /* Center text and inline elements */
  }
  
  .pdf-iframe {
    display: block; /* Ensures the iframe is treated as a block element */
    width: 80%; /* Adjust width as needed */
    height: 500px; /* Adjust height as needed */
    margin: 0 auto; /* Centers the iframe horizontally */
    border: none;
  }
  
    